import React from "react"
import { graphql } from "gatsby"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import ComicViewer from "../components/comic-viewer"
import Section from "../components/section"
import Separator from "../components/separator"
import Share from "../components/share"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const pages = data.pages.edges.map(edge => edge.node.childImageSharp.fluid)

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section
        style={{
          margin: "5rem 0",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h2>Nuestra historia</h2>
        <Separator />
      </Section>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
        }}
      >
        <ComicViewer pages={pages}></ComicViewer>
      </div>
      <Share />
    </Layout>
  )
}

export const query = graphql`
  query ComicImagesQuery {
    pages: allFile(
      sort: { fields: name, order: ASC }
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/comic/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`
