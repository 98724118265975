import React, { useState, useRef } from "react"
import Img from "gatsby-image"
import css from "./comic-viewer.css"

import Button from "./button"

export default function ComicViewer(props) {
  const [currentPage, setCurrentPage] = useState(0)
  const sectionEl = useRef(null)
  const { pages } = props
  const isFirstPage = currentPage === 0
  const isLastPage = currentPage === pages.length - 1

  const scroll = () => {
    window.scroll({
      top: sectionEl.current.offsetTop - 75,
      behavior: "smooth",
    })
  }

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1)
      scroll()
    }
  }

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
      scroll()
    }
  }

  const showPage = () => {
    const page = sectionEl.current.querySelector(".gatsby-image-wrapper")
    page.style.opacity = 1
  }

  return (
    <section ref={sectionEl} {...{ css }}>
      <div>
        <Img
          style={{ opacity: 0 }}
          onLoad={showPage}
          fluid={pages[currentPage]}
          alt=""
        />
      </div>
      <div id="buttons">
        <Button
          style={{
            opacity: isFirstPage ? 0 : 1,
          }}
          onClick={previousPage}
        >
          Anterior
        </Button>

        <Button
          style={{
            opacity: isLastPage ? 0 : 1,
          }}
          onClick={nextPage}
        >
          Siguiente
        </Button>
      </div>
    </section>
  )
}
