import { css } from "@emotion/core"

export default css`
  margin: 5rem 1rem;

  div.gatsby-image-wrapper {
    opactity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  #buttons {
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
  }
`
